<template>
  <div class="iot-LargeDataScreen">
    <div class="iot-LargeDataScreen-title">
      <div class="time">
  {{timeNow}}
      </div>
      <div class="title">
        {{platform.platformName}}
      </div>
        <div class="btn" @click="goHome" >
          控制台
        </div>
    </div>
    <router-view/>
  </div>
</template>


<script>
export default {
  name: "LargeDataScreen",
  data(){
    return {
      platform: {},
      timeNow: '',
      timer:null,
    }
  },
  methods: {
    getTime() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(() => {
        this.timeNow = this.dayjs().format('YYYY-MM-DD HH:mm:ss')
      },1000)
    },
    goHome(){
      this.$router.push('/home');
      this.$store.commit("setActiveMenu", "/home");
    },
     getLogo() {
      this.$api.platform
        .getPlatform(this.$store.state.userId)
        .then((response) => {
          if (response.code === 200) {
            this.platform = response.data;
          }
        });
    },
  },
  created(){
    this.getLogo();
    this.getTime()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
  };
</script>
<style scoped>
.iot-LargeDataScreen{
  width: 100%;
  height: 100%;
   background: url('../../public/backImg/back.jpg')no-repeat;
   background-size:  100% 100%;
}
.iot-LargeDataScreen-title{
    width: 100%;
    height: 68px;
  color: #ccf2ff;
   display: flex;
   justify-content: space-between;
   padding:0 150px;
   box-sizing: border-box;
}
.iot-LargeDataScreen-title .title{
   line-height: 68px;
   font-size: 45px;
   text-align: center;
   
}
.time{
  margin-left: -50px;
  letter-spacing: 2px;
  font-family: '时钟字体';
  font-style: italic;
  padding-top: 30px;
  font-size: 20px;
  color:#fff
}
.iot-LargeDataScreen-title .btn{
  margin: 25px 0;
  height: 30px;
  width: 100px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-radius: 8px;
  background: rgb(36,41,194);
  cursor: pointer;
}
</style>
